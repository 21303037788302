import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CampaignService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public getCampaigns(data: string, isQrId: boolean): Observable<any> {
    // debugger
     if (isQrId) {
       return this.http.get(`${this.baseUrl}/qrcode/${data}/dashboard/info`).pipe(map(res => res));
     } else {
      return this.http.get(`${this.baseUrl}/organization/${data}/dashboard/info`).pipe(map(res => res));
     }
  }

  public getIndividualCampaigns(data: string, isQrId: boolean): Observable<any> {
    if (isQrId) {
      return this.http.get(`${this.baseUrl}/qrcode/${data}/dashboard/info`).pipe(map(res => res));
    } else {
      return this.http.get(`${this.baseUrl}/individual/${data}/dashboard/info`).pipe(map(res => res));
    }
  }

  public getQRCodeURL(qrId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/qrcode/${qrId}/url`).pipe(map(res => res));
  }

  public getAboutUs(orgId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/organization/${orgId}/aboutus`).pipe(map(res => res));
  }

  public getBulletinImage(orgId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/organization/${orgId}/bulletin/images`).pipe(map(res => res));
  }

  public getBulletinVideos(orgId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/organization/${orgId}/bulletin/videos`).pipe(map(res => res));
  }
  public getCampaignUpdates(campaignId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/campaign/${campaignId}/updates`).pipe(map(res => res));
  }

  public getCampaignInfo(qrId: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/campaign/${qrId}/info`).pipe(map(res => res));
  }
}
