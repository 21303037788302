import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { HandleErrors, ToastMessage } from '../../_helpers/commonfunction';
import { ValidatorService } from '../../_helpers/validator.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, OnDestroy {

  form: FormGroup;
  @Output() SignInResultEvent = new EventEmitter<any>();
  signInSpinner ='signInSpinner'
  constructor(private accountService: AccountService, private authService: AuthenticationService
    , private spinnerService: NgxSpinnerService, private common: CommonService, private toastMessage: ToastMessage, private handleErrors: HandleErrors) {
  }
  ngOnDestroy(): void {
      
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, ValidatorService.noWhitespaceValidator]),
      password: new FormControl('', [
        Validators.required,
        ValidatorService.noWhitespaceValidator
      ])
    })
  }
  
  onSignIn() {
    if (this.form.valid) {
      this.spinnerService.show(this.signInSpinner);

      this.accountService.signIn(this.form.value).subscribe((res: any) => {
        this.spinnerService.hide(this.signInSpinner);
        if (res) {
          this.authService.setSignInResponse(res);
          let data: any = {
            isLoggedIn: true
          }
          this.SignInResultEvent.next(data);
        }

      }, error => {
        // handle error
        this.spinnerService.hide(this.signInSpinner);
        this.handleErrors.HandleError(error);
       
      });
    }
    else {
      this.common.markAsTouchedFormControl(this.form);
    }
    
  }

  onCancel() {
    let data: any = {
      isCancel: true
    }
    this.SignInResultEvent.next(data);
  }

  onForgetPassword() {
    let data: any = {
      isCancel: true,
      isForgetPassword :true
    }
    this.SignInResultEvent.next(data);
  }

  onSignUpClick() {
    let data: any = {
      isCancel: true,
      isForgetPassword: false,
      isSignUp :true
    }
    this.SignInResultEvent.next(data);
  }
}
