<div class="form-content">
  <div class="row login-data" [formGroup]="form">
    <ngx-spinner type="ball-scale-multiple" size="medium"  [name]="signInSpinner"></ngx-spinner>
    <!-- <div class="col-sm-12">
      <img src="../../../assets/images/login-img.jpg" class="card-img-top" />
    </div> -->
    <div class="login-content p-0"><h2>Sign <strong>in</strong></h2></div>
    <div class="col-sm-12 mt-10">
      <div class="input-icon mb-3">
        <input type="text" class="form-control" name="username" id="username" placeholder="Username"
               formControlName="username" />
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      </div>
      <div *ngIf="form.controls['username'].invalid &&
                           (form.controls['username'].touched || form.controls['username'].dirty )">
        <small class="text-danger notvalid" *ngIf="form.controls['username'].errors.required || form.controls['username'].errors.whitespace ">
          Username is required
        </small>
      </div>
    </div>
    <div class="col-sm-12  mt-10">
      <div class="input-icon">
        <input type="Password" class="form-control" name="Pass" id="exampleInputPassword1" for="exampleInputPassword1" placeholder="Password"
               formControlName="password" required />
        <i class="fa fa-key icon-abs" aria-hidden="true"></i>

      </div>

      <div *ngIf="form.controls['password'].invalid &&
                         (form.controls['password'].touched || form.controls['password'].dirty )">
        <small class="text-danger notvalid" *ngIf="form.controls['password'].errors.required || form.controls['password'].errors.whitespace ">
          Password is required
        </small>
      </div>
      <div class="actionFormLink d-flex align-item-center justify-content-between flex-wrap newclass">
        <a class="note_help_main  forget-password cursor-pointer py-3 d-block " (click)="onSignUpClick()">Sign Up</a>
        <a class="note_help_main  forget-password cursor-pointer py-3 d-block " (click)="onForgetPassword()">Forgot Password?</a>
      </div>
      <!--<div class="text-end">
        <a class="note_help_main  forget-password cursor-pointer py-3 d-block text-end" (click)="onForgetPassword()">Forgot Password?</a>
      </div>-->
    </div>
    <div class="col-sm-12 mt-10">
      <div class="d-flex justify-content-center nav-buttons">
        <a class="actionslightBtnLinks login me-lg-3 me-md-3 me-sm-0 me-0" (click)="onSignIn()">
          Sign In
        </a>
        <a class="actionslightBtnLinks signup" (click)="onCancel()">
          Cancel
        </a>
      </div>

      
      <!--<input type="submit" name="log" id="log" value="Sign In" class="button not-allowed">-->
    </div>
    <!--<div class="col-sm-12">
    <label for="campaign_name" class="form-label">Name</label>
    <ejs-textbox class="form-control " formControlName="username" placeholder="Username"
                 [ngClass]="{ 'is-invalid': (form.controls['username'].touched ) && form.controls['username'].errors, 'is-valid': form.controls['username'].valid }"></ejs-textbox>


    <div *ngIf="form.controls['username'].invalid &&
                         (form.controls['username'].touched || form.controls['username'].dirty )">
      <small class="text-danger" *ngIf="form.controls['username'].errors.required || form.controls['username'].errors.whitespace ">
        Name is required
      </small>
    </div>
  </div>
  <div class="col-sm-12">
    <label for="campaign_name" class="form-label">Name</label>
    <ejs-textbox class="form-control " type="password" formControlName="password" placeholder="Username"
                 [ngClass]="{ 'is-invalid': (form.controls['password'].touched ) && form.controls['password'].errors, 'is-valid': form.controls['password'].valid }"></ejs-textbox>


    <div *ngIf="form.controls['password'].invalid &&
                         (form.controls['password'].touched || form.controls['password'].dirty )">
      <small class="text-danger" *ngIf="form.controls['password'].errors.required || form.controls['password'].errors.whitespace ">
        Password is required
      </small>
    </div>
  </div>-->

  </div>
</div>
