<div class="form-content">
  <div class="row login-data" [formGroup]="form">
    <ngx-spinner type="ball-scale-multiple" size="medium" [name]="signInSpinner"></ngx-spinner>
    <!-- <div class="col-sm-12">
      <img src="../../../assets/images/login-img.jpg" class="card-img-top" />
    </div> -->
    <!-- <div class="login-header text-end mb-3">Forgot <strong>Password</strong></div> -->
    <div class="login-content p-0"><h2>Forgot <strong>Password</strong></h2></div>
    <div class="col-sm-12 mt-10">
      <div class="input-icon mb-3">
        <input type="text" class="form-control" name="username" id="username" placeholder="Username"
               formControlName="username" />
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>


      </div>
      <div *ngIf="form.controls['username'].invalid &&
                           (form.controls['username'].touched || form.controls['username'].dirty )">
        <small class="text-danger notvalid" *ngIf="form.controls['username'].errors.required || form.controls['username'].errors.whitespace ">
          Username is required
        </small>
      </div>
    </div>
    <div class="col-sm-12 mt-10">
      <div class="input-icon">
        <input type="text" placeholder="Email Address"
               formControlName="email" class="form-control" />

        <small class="text-danger" *ngIf="form.controls['email'].touched && !form.controls['email'].dirty">Email is required</small>
        <i class="fa fa-envelope icon-abs" aria-hidden="true"></i>
      </div>
      <div *ngIf="form.controls['email'].invalid &&
                           (form.controls['email'].touched || form.controls['email'].dirty )">
        <small class="text-danger" *ngIf="form.controls['email'].errors && form.controls['email'].dirty">
          Please enter valid Email address
        </small>
      </div>
    </div>
    <div class="col-sm-12 mt-10">
      <div class="d-flex justify-content-center ">
        <button class="actionslightBtnLinks me-lg-3 me-md-3 me-sm-0 me-0 signInbtn" (click)="onForgetPassword()">
          Submit
        </button>
        <button class="actionslightBtnLinks signInbtn" (click)="onCancel()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
