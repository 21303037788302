<!-- <section class="newsong_section">
  <div class="container-new">
      <div class="newsong_row">
          <h1 class="newsong_title">{{orgAboutUs?.name}}</h1>
          <div class="newsong_info">
            <p>About Us</p>
            <div class="newsong_barcode">
              
            </div>
          </div>
      </div>
  </div>
</section> -->

 <!-- Newsong Section start -->

 <main class="inside-body-content">
    <!-- Hero Section -->
    <section class="features-hero-section purple-bg-color singleHeader py-lg-5 py-md-5 py-sm-5 py-5" >
        <article class="container-new topheader-feature-relative">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
                    <div class="features-content position-relative ">
                        <h1 class="banner-title text-white mb-0" >
                            {{orgAboutUs?.name}}
                        </h1>
                    </div>
                </div>
            </div>
        </article>
    </section>
    <!-- /Hero Section -->
    <section class="scan-QR-code-section">
        <article class="container-new">
            <div class="row">
                <div class="col-12">
                    <div class="scan-QR-boxes">
                        <div class="QRBox-content d-flex align-items-center position-relative">
                            <p class="para">About Us</p>
                            <img src="../../../assets/images/favicon.png"
                                class="img-fuild scanQRImageShow topFeatures-icon-images p-2 scanQrcodeImage" alt="Scan QR" />
                        </div>

                    </div>
                </div>
            </div>
        </article>
    </section>
    <!-- terms Section -->

    <section class="information_box px-lg-0 px-md-0 px-sm-3 px-3">
        <article class="container-new">
            <div class="description desciption-about-details bg-white p-lg-4 p-md-4 p-sm-2 p-2 my-lg-4 my-md-4 my-sm-3 my-3">

                <div class="row m-0 w-100">
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12 px-lg-3 px-md-2 px-sm-2 px-0">
                        <img src="../../../assets/images/campain_img_1.png" alt="about-us-image" class="w-100 img-fluid aboutUsImageSide"/>
                    </div>
                    <div class="co-lg-6 col-md-6 col-sm-12 col-12 px-lg-3 px-md-2 px-sm-2 px-0">
                        <div class="allContentAboutUs">
                            <div class="details-ab-content-box">
                                <span>EIN:</span><strong>{{orgAboutUs?.ein}}</strong>
                            </div>
                            <div class="details-ab-content-box">
                                <span>Email:</span><strong>{{orgAboutUs?.email}}</strong>
                            </div>
                            <div class="details-ab-content-box">
                                <span>Address:</span><strong>{{orgAboutUs?.address}}</strong>
                            </div>
                            <div class="details-ab-content-box">
                                <div class="noteabout-us">
                                    <p class="mb-0"> {{orgAboutUs?.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <h2>{{orgAboutUs?.name}}</h2> -->
                <!-- <br> -->
                
                <!-- <div class="details-ab-content-box">
                    <span><b>Email:</b>{{orgAboutUs?.email}} </span>
                </div>
                <div class="details-ab-content-box">
                    <span><b>Address:</b>{{orgAboutUs?.address}}</span>
                </div>
                <div class="details-ab-content-box">
                    {{orgAboutUs?.description}}
                </div> -->
            </div>
        </article>
    </section>
</main>
  <!-- Newsong Section End -->

<!-- 
    <div class="container flex-column">

        <div class="description">


           





            <div>
                <span><b>EIN:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{orgAboutUs?.ein}} </span>
            </div>
            <div>
                <span><b>Email:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{orgAboutUs?.email}} </span>
            </div>
            <div>
                <span><b>Address:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{orgAboutUs?.address}}</span>
            </div>
            <div>
                {{orgAboutUs?.description}}
            </div>
        </div>
    </div> -->
