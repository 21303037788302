import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private campaignsData: any; // Adjust type as needed

  private campaignsDataSubject = new BehaviorSubject<any>(null); // Initialize with null or default value
  campaignsData$ = this.campaignsDataSubject.asObservable();
  constructor(private domSanitizer: DomSanitizer, private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  isNavigationLinkVisible = false;

  storeCartType = "store";
  eventCartType = "event";
  auctionCartType = "auction";
  raffleCartType = "raffle";
  storeNavigationData: any = {
    isStoreNavigationLinkVisible: false,
    routerLink: [],
    queryParams: {},
  };
  showNavigationLink() {
    this.isNavigationLinkVisible = true;
  }


  setCampaignsData(data: any): void {
    this.campaignsDataSubject.next(data); // Update the BehaviorSubject with new data
  }

  getCartContents(organizationId: string): any {
    const cartData = localStorage.getItem(this.raffleCartKey);
    if (!cartData) return null;

    try {
      const parsedCart = JSON.parse(cartData);
      return parsedCart.organization_id === organizationId ? parsedCart : null;
    } catch (e) {
      return null;
    }
  }


  eventNavigationData: any = {
    isEventNavigationLinkVisible: false,
    routerLink: [],
    queryParams: {},
  };

  auctionNavigationData: any = {
    isAuctionNavigationLinkVisible: false,
    routerLink: [],
    queryParams: {},
  }
  raffleNavigationData: any = {
    isRaffleNavigationLinkVisible: false,
    routerLink: [],
    queryParams: {},
  };

  setStoreNavigationLinkData(linkVisible: boolean, routerLink: any, queryParams: any) {
    this.storeNavigationData.isStoreNavigationLinkVisible = linkVisible;
    this.storeNavigationData.routerLink = routerLink;
    this.storeNavigationData.queryParams = queryParams;
  }

  setEventNavigationLinkData(linkVisible: boolean, routerLink: any, queryParams: any) {
    this.eventNavigationData.isEventNavigationLinkVisible = linkVisible;
    this.eventNavigationData.routerLink = routerLink;
    this.eventNavigationData.queryParams = queryParams;
  }

  setAuctionNavigationLinkData(linkVisible: boolean, routerLink: any, queryParams: any) {
    this.auctionNavigationData.isAuctionNavigationLinkVisible = linkVisible;
    this.auctionNavigationData.routerLink = routerLink;
    this.auctionNavigationData.queryParams = queryParams;
  }

  setRaffleNavigationLinkData(linkVisible: boolean, routerLink: any, queryParams: any) {
    this.raffleNavigationData.isRaffleNavigationLinkVisible = linkVisible;
    this.raffleNavigationData.routerLink = routerLink;
    this.raffleNavigationData.queryParams = queryParams;
  }


  markAsTouchedFormControl(formGroup: FormGroup) {
    for (let inner in formGroup.controls) {
      formGroup.get(inner).markAsTouched();
      formGroup.get(inner).markAsDirty();

    }
    this.scrollToError()
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  setUpNavigationMenu(campaignsData: any, id: any, fetchedParams: any) {
    if (campaignsData) {
      if (campaignsData?.account_type == "user") {

        // go back
        this.isNavigationLinkVisible = false;
      }
      else if (campaignsData?.account_type == "organization") {
        this.isNavigationLinkVisible = true;
        if (campaignsData.available_services?.length > 0) {
          let isStoreAvailableData: any = [];
          let isEventAvailableData: any = [];
          let isAuctionAvailableData: any = [];
          let isRaffleAvailableData: any = [];
          isEventAvailableData = campaignsData.available_services.filter((s: any) => {
            return s.Key == "event" && s.Value
          })

          isStoreAvailableData = campaignsData.available_services.filter((s: any) => {
            return s.Key == "store" && s.Value
          })

          isAuctionAvailableData = campaignsData.available_services.filter((s: any) => {
            return s.Key == "auction" && s.Value
          })

          isRaffleAvailableData = campaignsData.available_services.filter((s: any) => {
            return s.Key == "raffle" && s.Value
          })

          this.setEventNavigationLinkData(false, [], fetchedParams);
          this.setStoreNavigationLinkData(false, [], fetchedParams);
          this.setAuctionNavigationLinkData(false, [], fetchedParams);
          if (isStoreAvailableData && isStoreAvailableData.length > 0) {
            if (campaignsData.storefronts?.length > 0) {
              // create store link for header menu
              campaignsData.storefronts = campaignsData.storefronts.filter((x: any) => {
                return x.open
              });

              let isOpenStoreAny = campaignsData.storefronts.some((x: any) => {
                return x.open
              });
              if (isOpenStoreAny) {
                let storefront_id = campaignsData.storefronts[0].storefront_id;
                if (id) {

                  storefront_id = id;
                }
                this.setStoreNavigationLinkData(true, ['/shop',storefront_id], fetchedParams);
              }else{
                this.setStoreNavigationLinkData(true, ['/shop'], fetchedParams);
              }
            }
          }
          if (isEventAvailableData && isEventAvailableData.length > 0) {
            this.setEventNavigationLinkData(true, ['/event'], fetchedParams);
          }
          if (isAuctionAvailableData && isAuctionAvailableData.length > 0) {
            this.setAuctionNavigationLinkData(true, ['/auction'], fetchedParams);
          }

          if (isRaffleAvailableData && isRaffleAvailableData.length > 0) {
            this.setRaffleNavigationLinkData(true, ['/raffle'], fetchedParams);
          }
        }
      }
    }
  }


  getCart(organizationId: any, type: string) {
    if (type == this.storeCartType) {
      let data = localStorage.getItem(this.productCartKey);
      if (data) {
        try {
          let cartData = JSON.parse(data);
          if (organizationId == cartData.organization_id) {
            return cartData;
          }

        }
        catch (e) {
          this.emptyCart(type);
        }


      }
    }
    else if (type == this.eventCartType) {
      let data = localStorage.getItem(this.eventCartKey);
      if (data) {
        try {
          let cartData = JSON.parse(data);
          if (organizationId == cartData.organization_id) {
            return cartData;
          }

        }
        catch (e) {
          this.emptyCart(type);
        }


      }
    }
    else if (type == this.auctionCartType) {
      let data = localStorage.getItem(this.auctionCartKey);
      if (data) {
        try {
          let cartData = JSON.parse(data);
          if (organizationId == cartData.organization_id) {
            return cartData;
          }

        }
        catch (e) {
          this.emptyCart(type);
        }


      }
    }
    else if (type == this.raffleCartType) {
      let data = localStorage.getItem(this.raffleCartKey);
      if (data) {
        try {
          let cartData = JSON.parse(data);
          if (organizationId == cartData.organization_id) {
            return cartData;
          }

        }
        catch (e) {
          this.emptyCart(type);
        }


      }
    }

    return null;

  }

  addToCart(data: any, type: string) {
    let cartData = JSON.stringify(data);
    if (type == this.storeCartType) {
      localStorage.setItem(this.productCartKey, cartData);
    }
    else if (type == this.eventCartType) {
      localStorage.setItem(this.eventCartKey, cartData);
    }
    else if (type == this.auctionCartType) {
      localStorage.setItem(this.auctionCartKey, cartData);
    }
    else if (type == this.raffleCartType) {
      localStorage.setItem(this.raffleCartKey, cartData);
    }

  }


  addToPrizeCart(){

  }



  emptyCart(type: string) {
    if (type == this.storeCartType) {
      localStorage.removeItem(this.productCartKey);
    }
    else if (type == this.eventCartType) {
      localStorage.removeItem(this.eventCartKey);
    }
    else if (type == this.auctionCartKey) {
      localStorage.removeItem(this.auctionCartKey);
    }
    else if (type == this.raffleCartType) {
      localStorage.removeItem(this.raffleCartKey);
    }

  }
  productCartKey = 'ProductCart';
  eventCartKey = 'EventCart';
  auctionCartKey = 'AuctionCart';
  raffleCartKey = 'RaffleCart';

  getCartProductBadge(organizationId: any, type: string) {
    let cart: any = this.getCart(organizationId, type);
    if (cart && cart.products && cart.products?.length > 0) {
      return cart.products?.length;
    }
    return 0;
  }

  getTrustedVideoURL(videoURl: any): SafeResourceUrl {
    let srclink = videoURl;

    if (srclink.startsWith('https://www.youtube.com/watch?v=')) {
      let embedlink = srclink.replace('watch?v=', 'embed/')
      let embedlinkFunctions = embedlink + '?loop=1&autoplay=1&mute=1'
      return this.domSanitizer.bypassSecurityTrustResourceUrl(embedlinkFunctions);
    } else if (srclink.startsWith('https://youtu.be')) {
      let embedlink = srclink.replace('https://youtu.be', 'https://www.youtube.com/embed/')
      let embedlinkFunctions = embedlink + '?loop=1&autoplay=1&mute=1'
      return this.domSanitizer.bypassSecurityTrustResourceUrl(embedlinkFunctions);
    }
    else {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(srclink);
    }
  }

  getCartEventBadge(organizationId: any, type: string) {
    let cart: any = this.getCart(organizationId, type);
    if (cart && cart.ticketTypes && cart.ticketTypes?.length > 0) {
      return cart.ticketTypes?.length;
    }
    return 0;
  }

  getCartRaffleBadge(organizationId: any, type: string) {
    let cart: any = this.getCart(organizationId, type);
    if (cart && cart.raffles && cart.raffles?.length > 0) {
      return cart.raffles?.length;
    }
    return 0;
  }

  public getActionTracking(emailid: any, actionData: any): Observable<any> {
    const body = actionData;
    return this.http.put(`${this.baseUrl}/organization/tracking/email/${emailid}/status`, body).pipe(map(res => res));
  }

  public applyPromoCodes(organizationId: any, promoCodeData: any){
    const body = promoCodeData;
    return this.http.post(`${this.baseUrl}/organization/${organizationId}/promocodes/discount`, body).pipe(map(res => res));
  }

  campaignData: any;
  getCampaignInfoEventTrigger() {
    return this.campaignInfoEventTrigger;
  }

  campaignInfoEventTrigger: EventEmitter<any> = new EventEmitter<any>();
  emitCampaignInfo(data: any) {
    this.campaignInfoEventTrigger.emit(data);
    if (data.data) {
      this.campaignData = data.data;
    }
  }

  public getReferralLink(account_id: any) {
    return this.http.get(`${this.baseUrl}/${account_id}/referral/link`);
  }

  public getReferralProgram(programId: any) {
    return this.http.get(`${this.baseUrl}/referral/program/${programId}`);
  }
}
