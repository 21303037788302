import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faBars, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HandleErrors, ToastMessage } from '../../../_helpers/commonfunction';
import { Util } from '../../../_helpers/util';

@Component({
  selector: 'app-new-ui-header',
  templateUrl: './new-ui-header.component.html',
  styleUrls: ['./new-ui-header.component.css']
})
export class NewUiHeaderComponent implements OnInit, OnDestroy {
  fatchedParams: any;
  sub: any
  qrId: any;
  homeActive: boolean = false
  aboutActive: boolean = false
  campaignsData: any;
  storeId: any;
  campaign: any;
  faBars = faBars;
  showMyAccountMenu: boolean = false;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  @ViewChild('sidebar') sidebar?: SidebarComponent;
  public closeOnDocumentClick: boolean = true;
  public type: string = 'Over';
  public target: string = '.content';
  public enablePersistence: boolean = true;
  currentUrl = "";
  campaignDataEventSubscription: any;
  public onCreated(args: any) {

    (this.sidebar as SidebarComponent).element.style.visibility = '';
  }
  btnClick() {
    (this.sidebar as SidebarComponent).position = "Right";
    this.sidebar?.show();
  }
  closeClick() {
    this.sidebar?.hide();
  }
  private modalRef: NgbModalRef;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private campaignService: CampaignService,
    public common: CommonService,
    public authService: AuthenticationService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private handleErrors: HandleErrors, private toastMessage: ToastMessage
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: any) => {
        this.currentUrl = event?.url
        if (!this.campaignsData) {
          if (!this.currentUrl?.split('/')[1]?.startsWith('home')
            // && !this.currentUrl?.split('/')[1]?.startsWith('campaign')
            // && !this.currentUrl?.split('/')[1]?.startsWith('giveanywhere')
          ) {
            // handle URL for the individual and the organization referral white label page
            const hasIndividualId = this.currentUrl.includes('individualid');
            hasIndividualId ? this.getProfile() : this.getDashboardInfo();

            // // call dashboard info
            // this.getDashboardInfo();
          }
        }


      });

    this.activatedRoute.queryParams.subscribe(params => {
      this.fatchedParams = params;
    });
  }

  ngOnInit(): void {

    this.authService.getSignInAndSignUpEventTrigger().subscribe((data: any) => {
      if (data?.isIndividualSignUpClicked) {
        this.openSignInModal(false, true);
      }
      else if (data?.isLoginClicked) {
        this.openSignInModal(true, false);
      }
    });
    this.campaignDataEventSubscription = this.common.getCampaignInfoEventTrigger().subscribe((data: any) => {
      if (!data?.isLoading && data.data) {
        this.campaignsData = data.data;
        this.common.setUpNavigationMenu(data.data, "", this.fatchedParams);
      }
    })

    this.getUserProfile();
    
  }

  ngOnDestroy(): void {
    this.campaignDataEventSubscription?.unsubscribe();
  }

  getDashboardInfo() {
    if (this.fatchedParams.organizationid) {
      this.common.emitCampaignInfo({ isLoading: true, data: null })

      this.campaignService.getCampaigns(this.fatchedParams.organizationid, false).subscribe(res => {
        this.campaignsData = res;
        this.common.emitCampaignInfo({ isLoading: false, data: res })
        // this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);

        // Filter params based on the presence of referral programid
        const paramsToPass = this.currentUrl.includes('programid')
        ? this.filterParams(['programid', 'referral_code'])
        : this.fatchedParams;

        // Call setUpNavigationMenu with filtered or full params
        this.common.setUpNavigationMenu(this.campaignsData, "", paramsToPass);
      });
    }
  }

  private filterParams(keysToExclude: string[]): any {
    return Object.keys(this.fatchedParams)
      .filter(key => !keysToExclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.fatchedParams[key];
        return obj;
      }, {});
  }  

  getIndividualDashboardInfo() {
    this.campaignService.getIndividualCampaigns(this.fatchedParams.organizationid, false).subscribe(res => {
      this.campaignsData = res;

      this.common.setUpNavigationMenu(this.campaignsData, "", this.fatchedParams);
    });
  }

  showHomeBgcolor() {
    // this.homeActive = true
    // this.aboutActive = false
  }

  showAboutBgcolor() {
    // this.aboutActive = !this.aboutActive
    //this.homeActive = !this.homeActive
  }

  redirectToWebsite() {
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/home`
    window.open(redirectingURL, "_blank");
  }

  onLogout() {
    this.userProfileData = null;
    this.authService.onLogout();
    this.authService.emitLogoutEventTrigger({ logout: true });
  }

  signin() {
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/auth/login`
    window.open(redirectingURL, "_blank");
  }

  signup() {
    let URL = environment.website_domain
    let redirectingURL = `https://` + URL + `.com/signup/individualsignup`
    window.open(redirectingURL, "_blank");
  }

  getProfile(){
    if (this.fatchedParams.individualid) {
      this.accountService.getIndividualProfile(this.fatchedParams.individualid).subscribe(res => {
        this.campaignsData = res;
        this.common.emitCampaignInfo({ isLoading: false, data: res })
      }); 
    }
  }

  @ViewChild('signInModal') signInModal: any; // Reference to modal template

  openSignInModal(isLoginClicked, isIndividualSignUp ) {
    this.isLoginClicked = isLoginClicked;
    this.isIndividualSignUp = isIndividualSignUp;
    this.modalRef = this.modalService.open(this.signInModal, {
      backdrop: 'static', // Prevent closing by clicking outside
      keyboard: false,
      centered: true
    });
  }

  closeSignInModal(): void {
    if (this.modalRef) {
      this.isForgetPassword = false;
      this.isLoginClicked = false;
      this.isResetPassword = false;
      this.isVerification = false;
      this.isIndividualSignUp = false;
      this.modalRef.close();
    }
  }

  isForgetPassword = false;
  isLoginClicked = false;
  isResetPassword = false;
  isVerification = false;
  isIndividualSignUp = false;

  getSignInResult(data: any) {
    if (data.isCancel) {
      if (data.isForgetPassword) {
        this.isForgetPassword = true;
      }
      else if (data.isSignUp) {
        this.isIndividualSignUp = true;
        return;
      }
      else {
        this.closeSignInModal();
      }
      this.isLoginClicked = false;
    }
    else if (data.isLoggedIn) {
      this.isLoginClicked = false;
      this.closeSignInModal();
      this.getUserProfile();
      this.authService.emitSignInResultEventTrigger({ isLoggedIn: true })
    }
  }
  getForgetPasswordResult(data: any) {

    this.isForgetPassword = false;
    
    if (data.isCancel) {
      this.isLoginClicked = true;
    }
    else if (data.isSuccess) {
      this.isLoginClicked = false;
      // update password
      this.isResetPassword = true;
    }
  }
  getResetPasswordResult(data: any) {
    this.isResetPassword = false;
    if (data.isCancel) {
      this.isForgetPassword = false;
      this.isLoginClicked = true;
    }
    else if (data.isSuccess) {
      this.isLoginClicked = true;

      // update password
    }
  }


  getVerificationResult(data: any) {
    this.isVerification = false;
    this.isIndividualSignUp = false;
    if (data.isCancel) {
      this.isLoginClicked = false;
    }
    else if (data.isSuccess) {
      this.isLoginClicked = true;
    }
  }

  getSignUpResult(data: any) {
    this.isIndividualSignUp = false;
    if (data.isCancel) {
      if (!this.isLoginClicked) {
        this.closeSignInModal();
      }
    }
    else if (data.isSignUp) {
      this.isVerification = true;
    }
  }
  userProfileData: any;
  getUserProfile() {
    if (this.authService.isUserLoggedIn()) {
      let accountInfo = this.authService.getAccount();
      this.accountService.getUserProfile(accountInfo).subscribe((res: any) => {
        if (res) {
          this.userProfileData = res;
          this.setProfileImage();
        }
      }, error => {
        // handle error
        this.handleErrors.HandleError(error);
        if (error.status == 401) { // Token Expired Or Unauthorized
          this.onLogout();
        }
      });
    }
  }
  isImageLoading = false;
  setProfileImage() {
    let accountInfo = this.authService.getAccount();
    if (Util.isOrganization(accountInfo.type)) {
      this.userProfileData?.care_ofs.forEach((element) => {
        let image_url = element.image_url;
        // this.uploadImage = image_url
        this.isImageLoading = true;
        this.userProfileData.customImageURL = image_url;
        
        // console.log("upload Image", this.uploadImage)
      })
    } else {
      this.isImageLoading = true;
      this.userProfileData.customImageURL = this.userProfileData?.image_url;
    }
  }

  onProfileImageLoad(): void {
    this.isImageLoading = false; // Hide the placeholder when the image loads
  }

  onProfileImageError(): void {
    this.isImageLoading = true; // Optionally handle image load errors
  }
}
