import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { HandleErrors, ToastMessage } from '../../_helpers/commonfunction';
import { ValidatorService } from '../../_helpers/validator.service';

@Component({
  selector: 'app-signup-verification',
  templateUrl: './signup-verification.component.html',
  styleUrls: ['./signup-verification.component.css']
})
export class SignupVerificationComponent implements OnInit, OnDestroy {

  form: FormGroup;
  @Output() VerificationResultEvent = new EventEmitter<any>();
  signInSpinner = 'signInSpinner'
  constructor(private accountService: AccountService, private authService: AuthenticationService, private spinnerService: NgxSpinnerService
    , private common: CommonService, private toastMessage: ToastMessage, private handleErrors: HandleErrors) {
  }
  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required, ValidatorService.noWhitespaceValidator])
    })
  }

  onOTPSubmit() {
    if (this.form.valid) {
      this.spinnerService.show(this.signInSpinner);

      this.accountService.verifyCode(this.form.value.code).subscribe((res: any) => {
        this.spinnerService.hide(this.signInSpinner);
        this.toastMessage.Success("Verified successfully", 5000);

        let data: any = {
          isSuccess: true
        }
        this.VerificationResultEvent.next(data);


      }, error => {
        // handle error
        this.spinnerService.hide(this.signInSpinner);
        this.handleErrors.HandleError(error);

      });
    }
    else {
      this.common.markAsTouchedFormControl(this.form);
    }

  }

  onCancel() {
    let data: any = {
      isCancel: true
    }
    this.VerificationResultEvent.next(data);
  }

  resendCode() {
    this.spinnerService.show(this.signInSpinner);
    this.accountService.resendVerificationCode().subscribe(
      res => {
        this.spinnerService.hide(this.signInSpinner);
        this.toastMessage.Success("OTP Sent Successfully", 5000);
      },
      error => {
        this.spinnerService.hide(this.signInSpinner);
        this.handleErrors.HandleError(error);
      }
    );
  }
}
