<div class="form-content">
  <div class="row login-data" [formGroup]="form">
    <ngx-spinner type="ball-scale-multiple" size="medium" [name]="signInSpinner"></ngx-spinner>
    <!-- <div class="col-sm-12">
      <img src="../../../assets/images/login-img.jpg" class="card-img-top" />
    </div> -->
    <div class="login-content p-0"><h2>Individual <strong>Signup</strong></h2></div>
    <div class="col-sm-12 mt-10">
      <div class="input-icon">
        <input type="text" class="form-control" name="username" id="username" placeholder="Username"
               formControlName="username"
               required />
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>


      </div>
      <div *ngIf="form.controls['username'].invalid &&
                           (form.controls['username'].touched || form.controls['username'].dirty )">
        <small class="text-danger notvalid" *ngIf="form.controls['username'].errors.required || form.controls['username'].errors.whitespace ">
          Username is required
        </small>
      </div>
    </div>
    <div class="col-sm-12 mt-10">
      <div class="input-icon">
        <input type="email" placeholder="Email Address"
               formControlName="email" class="form-control" />

        <small class="text-danger" *ngIf="form.controls['email'].touched && !form.controls['email'].dirty">Email is required</small>
        <i class="fa fa-envelope icon-abs" aria-hidden="true"></i>
      </div>
      <div *ngIf="form.controls['email'].invalid &&
                           (form.controls['email'].touched || form.controls['email'].dirty )">
        <small class="text-danger" *ngIf="form.controls['email'].errors && form.controls['email'].dirty">
          Please enter valid Email address
        </small>
      </div>
    </div>
    <div class="col-sm-12  mt-10">
      <div class="input-icon">
        <input type="Password" class="form-control" name="Pass" id="exampleInputPassword1" for="exampleInputPassword1" placeholder="Password"
               formControlName="password"
               required />
        <i class="fa fa-key icon-abs" aria-hidden="true"></i>

      </div>

      <div *ngIf="form.controls['password'].invalid &&
                         (form.controls['password'].touched || form.controls['password'].dirty )">

        <div class="">
          <!-- required -->
          <small class="text-danger notvalid" *ngIf="form.controls['password'].errors.required || form.controls['password'].errors.whitespace ">
            Password is required
          </small>

          <!-- password validation rules -->

          <div class="rules mt-2" *ngIf="!form.controls['password'].hasError('required')">
            <!-- minlength -->
            <div [ngClass]="{ 'text-success': !form.controls['password'].hasError('minlength'), 'text-danger': form.controls['password'].hasError('minlength') }">
              <div class="notvalid font-weight-light font-italic"
                   [attr.class]="!form.controls['password'].hasError('minlength') ? 'bi-check-square-fill' : 'bi-x-square'">
                Must be at least 8 characters long
              </div>
            </div>

            <!-- requires digit -->
            <div [ngClass]="{ 'text-success': !form.controls['password'].hasError('requiresDigit'), 'text-danger': form.controls['password'].hasError('requiresDigit') }">
              <div class="notvalid font-weight-light font-italic"
                   [attr.class]="!form.controls['password'].hasError('requiresDigit') ? 'bi-check-square-fill' : 'bi-x-square'">
                Must contain at least 1 digit
              </div>
            </div>

            <!-- requires uppercase -->
            <div [ngClass]="{ 'text-success': !form.controls['password'].hasError('requiresUppercase'), 'text-danger': form.controls['password'].hasError('requiresUppercase') }">
              <div class="notvalid font-weight-light font-italic"
                   [attr.class]="!form.controls['password'].hasError('requiresUppercase') ? 'bi-check-square-fill' : 'bi-x-square'">
                Must contain at least 1 uppercase character
              </div>
            </div>

            <!-- requires lowercase -->
            <div [ngClass]="{ 'text-success': !form.controls['password'].hasError('requiresLowercase'), 'text-danger': form.controls['password'].hasError('requiresLowercase') }">
              <div class="notvalid font-weight-light font-italic"
                   [attr.class]="!form.controls['password'].hasError('requiresLowercase') ? 'bi-check-square-fill' : 'bi-x-square'">
                Must contain at least 1 lowercase character
              </div>
            </div>

            <!-- requires special characters -->
            <div [ngClass]="{ 'text-success': !form.controls['password'].hasError('requiresSpecialChars'), 'text-danger': form.controls['password'].hasError('requiresSpecialChars') }">
              <div class="notvalid font-weight-light font-italic"
                   [attr.class]="!form.controls['password'].hasError('requiresSpecialChars') ? 'bi-check-square-fill' : 'bi-x-square'">
                Must contain at least 1 special character
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-12 mt-10">
      <div class="d-flex justify-content-center ">
        <!-- <button class="btn btn-primary signInbtn" (click)="onSignUp()">
          Sign Up
        </button>
        <button class="btn btn-secondary signInbtn" (click)="onCancel()">
          Cancel
        </button> -->
        <button class="actionslightBtnLinks login me-lg-3 me-md-3 me-sm-0 me-0" (click)="onSignUp()">
          Sign Up
        </button>
        <button class="actionslightBtnLinks signup" (click)="onCancel()">
          Cancel
        </button>
      </div>      
    </div>
  </div>
</div>
