import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  static noWhitespaceValidator(control: AbstractControl) {
    if (control.value != null && control.value != '' && control.value != undefined && control.value.toString() != '') {
      let isWhitespace = (control.value || '').trim().length === 0;
      let isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true }
    }
    return null;

  }
  static emailValidator = (control: AbstractControl) => {
    if (control.value != null && control.value != '') {
      // var EMAIL_REGEXP = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      var EMAIL_REGEXP = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
      if (!EMAIL_REGEXP.test(control.value)) {
        return { "invalidEmail": true };
      }

    }
    return null

  };

  static PasswordValidator(regex: RegExp, error: ValidationErrors) {
    return function PasswordValidator(control: AbstractControl) {
      if (control.value != null && control.value != '' && control.value != undefined) {
        const valid = regex.test(control.value);
        return valid ? null : error;
      }
      return null;
    }
  }

  static shippingProfileNameValidator(profileNamesList: any) {
    return function shippingProfileNameValidator(control: AbstractControl) {
      if (control.value != null && control.value != '' && control.value != undefined && profileNamesList && profileNamesList.length > 0) {
        let cuurentValue = control.value;
        let isExist = profileNamesList.some((x: any) => {
          return x?.name?.trim() == cuurentValue.trim()
        });
        if (isExist) {
          return {
            invalidName: true

          };
        }



      }
      return null;
    }
  }

  static PasswordMismatchValidator(passowrdControl: AbstractControl) {
    return function PasswordMismatchValidator(control: AbstractControl) {
      if (control.value != null && control.value != '' && control.value != undefined) {

        if (passowrdControl) {
          passowrdControl.valueChanges.subscribe(() => {
            control.updateValueAndValidity();
          });
        }
        const password = passowrdControl.value
        if (password != control.value) {
          return {
            mismatch: true

          };
        }

      }
      return null;
    }
  }

  static atLeastOneCheckboxCheckedValidator(
    minRequired = 1, controlKey: string
  ): ValidatorFn {
    return function validate(formary: AbstractControl) {
      let checked = 0
      if (formary instanceof FormArray) {
        if (formary && formary.length > 0) {
          for (let i = 0; i < formary.length; i++) {
            const formGroup = <FormGroup>formary.controls[i];

            if (formGroup.value[controlKey]) {
              checked++
              break;
            }


          }
        }

        
      }

      if (checked < minRequired) {
        return {
          atLeastOneRequiredBool: true,
        }
      }

      return null
    }
  }


}
