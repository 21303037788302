<div class="form-content">
  <div class="row login-data" [formGroup]="form">
    <ngx-spinner type="ball-scale-multiple" size="medium" [name]="signInSpinner"></ngx-spinner>
    <!--<div class="col-sm-12">
      <img src="../../../assets/images/login-img.jpg" class="card-img-top" />
    </div>-->
    <div class="login-header text-end">Email <strong>Verification</strong></div>
    <p class="fs-5">Authenticate Your Account</p>
    <div class="col-sm-12 mt-10">
      <div class="input-icon">
        <input type="Password"  placeholder="Enter One Time Password(OTP)" formControlName="code" class="form-control" />
        <i class="fa fa-key icon-abs" aria-hidden="true"></i>


      </div>
      <div *ngIf="form.controls['code'].invalid &&
                           (form.controls['code'].touched || form.controls['code'].dirty )">
        <small class="text-danger notvalid" *ngIf="form.controls['code'].errors.required || form.controls['code'].errors.whitespace ">
          Otp is required
        </small>
      </div>
      <p>If Verification Code NOT In Inbox Check Spam/Junk Folder</p>
      <p>If you didn’t get OTP,  <a (click)="resendCode()" class="resend-otp">Resend</a></p>
    </div>

    <div class="col-sm-12 mt-10">
      <div class="d-flex justify-content-center gap-3">
        <button class="btn btn-primary signInbtn" (click)="onOTPSubmit()">
          Submit
        </button>
        <button class="btn btn-secondary signInbtn" (click)="onCancel()">
          Cancel
        </button>
      </div>



    </div>


  </div>
</div>
