import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { HandleErrors, ToastMessage } from '../../_helpers/commonfunction';
import { ValidatorService } from '../../_helpers/validator.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;
  @Output() ResetPasswordResultEvent = new EventEmitter<any>();
  signInSpinner = 'signInSpinner'
  constructor(private accountService: AccountService, private authService: AuthenticationService, private spinnerService: NgxSpinnerService
    , private common: CommonService, private toastMessage: ToastMessage, private handleErrors: HandleErrors) {
  }
  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, ValidatorService.noWhitespaceValidator]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[0-9])"), {
          requiresDigit: true
        }),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[A-Z])"), {
          requiresUppercase: true
        }),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[a-z])"), {
          requiresLowercase: true
        }),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[$@^!%*?&])"), {
          requiresSpecialChars: true
        })
      ]),
      confirmPassword: new FormControl(''),
      code: new FormControl('', [Validators.required, ValidatorService.noWhitespaceValidator])
    })

    this.form.controls["confirmPassword"].addValidators([Validators.required, ValidatorService.PasswordMismatchValidator(this.form.controls["password"])])
  }

  onResetPassword() {
    if (this.form.valid) {
      this.spinnerService.show(this.signInSpinner);

      this.accountService.resetPassword(this.form.value).subscribe((res: any) => {
        this.spinnerService.hide(this.signInSpinner);
        
        this.toastMessage.Success("Password has been reset successfully", 5000);

        let data: any = {
          isSuccess: true
        }
        this.ResetPasswordResultEvent.next(data);


      }, error => {
        // handle error
        this.spinnerService.hide(this.signInSpinner);
        this.handleErrors.HandleError(error);

      });
    }
    else {
      this.common.markAsTouchedFormControl(this.form);
    }

  }

  onCancel() {
    let data: any = {
      isCancel: true
    }
    this.ResetPasswordResultEvent.next(data);
  }


}
