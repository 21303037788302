import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { HandleErrors, ToastMessage } from '../../_helpers/commonfunction';
import { ValidatorService } from '../../_helpers/validator.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;
  @Output() ForgetPasswordResultEvent = new EventEmitter<any>();
  signInSpinner = 'signInSpinner'
  constructor(private accountService: AccountService, private authService: AuthenticationService, private spinnerService: NgxSpinnerService
    , private common: CommonService, private toastMessage: ToastMessage, private handleErrors: HandleErrors) {
  }
  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, ValidatorService.noWhitespaceValidator]),
      email: new FormControl('', [Validators.required, ValidatorService.emailValidator]),
    })
  }

  onForgetPassword() {
    if (this.form.valid) {
      this.spinnerService.show(this.signInSpinner);

      this.accountService.forgotPassword(this.form.value).subscribe((res: any) => {
        this.spinnerService.hide(this.signInSpinner);
        this.toastMessage.Success("Code Has Been Sent Your Email", 5000);

        let data: any = {
          isSuccess: true
        }
        this.ForgetPasswordResultEvent.next(data);


      }, error => {
        // handle error
        this.spinnerService.hide(this.signInSpinner);
        this.handleErrors.HandleError(error);

      });
    }
    else {
      this.common.markAsTouchedFormControl(this.form);
    }

  }

  onCancel() {
    let data: any = {
      isCancel: true
    }
    this.ForgetPasswordResultEvent.next(data);
  }


}
