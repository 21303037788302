import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../../services/account.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';
import { HandleErrors, ToastMessage } from '../../_helpers/commonfunction';
import { ValidatorService } from '../../_helpers/validator.service';

@Component({
  selector: 'app-individual-signup',
  templateUrl: './individual-signup.component.html',
  styleUrls: ['./individual-signup.component.css']
})
export class IndividualSignupComponent implements OnInit, OnDestroy {

  form: FormGroup;
  @Output() SignUpResultEvent = new EventEmitter<any>();
  signInSpinner = 'signInSpinner'

  constructor(private accountService: AccountService, private authService: AuthenticationService
    , private spinnerService: NgxSpinnerService, private common: CommonService, private toastMessage: ToastMessage, private handleErrors: HandleErrors) {
  }
  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, ValidatorService.noWhitespaceValidator]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[0-9])"), {
          requiresDigit: true
        }),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[A-Z])"), {
          requiresUppercase: true
        }),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[a-z])"), {
          requiresLowercase: true
        }),
        ValidatorService.PasswordValidator(new RegExp("(?=.*[$@^!%*?&])"), {
          requiresSpecialChars: true
        })
      ])
      ,
      email: new FormControl('', [Validators.required,ValidatorService.emailValidator]),
    })
  }

  onSignUp() {
    if (this.form.valid) {
      this.spinnerService.show(this.signInSpinner);

      this.accountService.signUpUser(this.form.value).subscribe((res: any) => {
        this.spinnerService.hide(this.signInSpinner);
        if (res) {
          let account: any = {};
          account.id = res;
          this.authService.setAccount(account);
          this.toastMessage.Success("You Signed Up Succesfully", 5000);
          let data: any = {
            isSignUp: true
          }
          this.SignUpResultEvent.next(data);
        }

      }, error => {
        // handle error
        this.spinnerService.hide(this.signInSpinner);
        this.handleErrors.HandleError(error);

      });
    }
    else {
      this.common.markAsTouchedFormControl(this.form);
    }
  }

  onCancel() {
    let data: any = {
      isCancel: true
    }
    this.SignUpResultEvent.next(data);
  }

 
}
